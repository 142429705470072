import {Route, Routes} from "react-router-dom";
import React from "react";
import Home from "../scenes/default/Home";
import SimulationProductRetraiteDigital from "../scenes/default/simulation/product/RetraiteDigital";
import {MasterLayoutProvider} from "../components/layouts/default/Master";
import PageNotFound from "../scenes/PageNotFound";
import {SocketProvider} from "../providers/SocketProvider";

function Index(){
    return (
        <SocketProvider>
            <MasterLayoutProvider>
                <Routes>
                    <Route index path="/"  element={<Home/>}/>
                    <Route path="/simulation/product/retraite-digital/:uuid"  element={<SimulationProductRetraiteDigital/>}/>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </MasterLayoutProvider>
        </SocketProvider>

    );
}

export  default  Index;
