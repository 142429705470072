import toast from "react-hot-toast";

/**
 * @description Permet de traiter la response des requetes api
 * @param error
 * @param exceptionHandler
 */
export default (error, exceptionHandler) => {
    if (exceptionHandler !== undefined){
        exceptionHandler({
            code: error?.code,
            key: "ERROR",
            msg: error?.message
        });
    }else{
        if (error.message ==="Request failed with status code 401"){
            toast.error("Request failed with status code 401");
        }else{
            toast.error(error.message);
        }
    }
}
