import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {RootStateContext} from "../../../providers/RootProvider";

export const Header = () => {
    const rootStateContext = useContext(RootStateContext);
    const {user} = rootStateContext;

    const parameterColor = {
        "main-primary-color":user?.subsidiary?.parameters["main-primary-color"] ? user?.subsidiary?.parameters["main-primary-color"]["value"] : "#121b44",
        "main-secondary-color":user?.subsidiary?.parameters["main-secondary-color"] ? user?.subsidiary?.parameters["main-secondary-color"]["value"] : "#f45c04",
    }
    return (
        <header
            className='flex shadow-lg py-2 px-2 sm:px-2 bg-white font-[sans-serif] min-h-[70px] tracking-wide fixed top-0 left-0 right-0 z-50 main-header'
            style={{
                background : parameterColor["main-primary-color"],
                borderBottomColor : parameterColor["main-secondary-color"],
        }}>
            <div className='flex flex-wrap items-center justify-between gap-4 w-full'>
                <Link to="/"
                   className="lg:absolute max-lg:left-10 lg:top-2/4 lg:left-2/4 lg:-translate-x-1/2 lg:-translate-y-1/2">
                    {user?.subsidiary?.parameters["main-logo"] ? (
                        <img src={user?.subsidiary?.parameters["main-logo"]["cdn"]} alt="logo" className='w-36'/>
                    ): (
                        <img src="../../../assets/images/logo.png" alt="logo" className='w-36'/>
                    )}
                </Link>
                <div id="collapseMenu"
                     className='max-lg:hidden lg:!block max-lg:w-full max-lg:fixed max-lg:before:fixed max-lg:before:bg-black max-lg:before:opacity-50 max-lg:before:inset-0 max-lg:before:z-50'>
                    <button id="toggleClose"
                            className='lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white p-3'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 fill-black"
                             viewBox="0 0 320.591 320.591">
                            <path
                                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                                data-original="#000000"></path>
                            <path
                                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                                data-original="#000000"></path>
                        </svg>
                    </button>
                    <ul
                        className='lg:flex lg:gap-x-5 max-lg:space-y-3 max-lg:fixed max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-6 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50'>
                        <li className='mb-6 hidden max-lg:block'>
                            <Link to={"/"}>
                                <img src="../../../assets/images/favicon.png" alt="logo" className='w-36'/>
                            </Link>
                        </li>
                        <li className='max-lg:border-b max-lg:py-3 px-3'>
                            <Link to={"/"}
                               className='hover:text-[#fff] text-[#fff] block font-semibold text-[15px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                     className="size-6">
                                    <path
                                        d="M11.47 3.841a.75.75 0 0 1 1.06 0l8.69 8.69a.75.75 0 1 0 1.06-1.061l-8.689-8.69a2.25 2.25 0 0 0-3.182 0l-8.69 8.69a.75.75 0 1 0 1.061 1.06l8.69-8.689Z"/>
                                    <path
                                        d="m12 5.432 8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 0-.75-.75h-3a.75.75 0 0 0-.75.75V21a.75.75 0 0 1-.75.75H5.625a1.875 1.875 0 0 1-1.875-1.875v-6.198a2.29 2.29 0 0 0 .091-.086L12 5.432Z"/>
                                </svg>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='items-center ml-auto space-x-6'>
                    <button type="button" className={"flex items-center justify-center px-3 py-1.5 rounded-full text-white text-sm tracking-wider font-medium border border-current outline-none bg-["+parameterColor["main-secondary-color"]+"] hover:bg-["+parameterColor["main-secondary-color"]+"] active:bg-["+parameterColor["main-secondary-color"]+"]"} style={{background:parameterColor["main-secondary-color"]}}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                             className="size-4">
                            <path fillRule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clipRule="evenodd"/>
                        </svg>
                        <span className="pl-1">
                            Appeler
                        </span>
                    </button>
                    {/*<button id="toggleOpen" className='lg:hidden'>
                        <svg className="w-7 h-7" fill="#333" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                        </svg>
                    </button>*/}
                </div>
            </div>
        </header>
    );
}
