import React, {useContext} from "react";
import AppPreventLoading from "../AppPreventLoading";
import {RootStateContext} from "../../../providers/RootProvider";

export const MasterLayoutProvider = ({children}) => {
    const rootStateContext = useContext(RootStateContext);

    return (
        <div>
            {!rootStateContext.appState.isPreventLoading &&  (
                <div>
                    {children}
                </div>
            )}
            {rootStateContext.appState.isPreventLoading && <AppPreventLoading/>}
        </div>
    );
}
