export default {
    authenticate:{
        PROD:{
            endpoint : "https://icompro-api.jars-dev.com/api",
        },
        DEV:{
            endpoint : "https://icompro-api.jars-dev.com/api"
        }
    },
    default:{
        PROD:{
            endpoint: "https://icompro-api.jars-dev.com/api"
        },
        DEV:{
            endpoint: "https://icompro-api.jars-dev.com/api"
        }
    }
}
