import ConfigsApi from "../../../configs/Api";
import ConfigsEnv from "../../../configs/Env";
import axios from "axios";
import errorMiddleware from "../errorMiddleware";

/**
 *
 * @param dispatch
 * @param success
 * @param failure
 * @param exceptionHandler
 */
export const fetchProductList = (dispatch, success, failure, exceptionHandler) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/product/all?expand=status,product_parameter';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            failure({
                code: 500,
                key: "ERROR",
                msg:data.error
            });
        } else {
            success(data.products);
        }

    }).catch(error => {
        errorMiddleware(error,exceptionHandler);
    });
}


/**
 *
 * @param dispatch
 * @param uuid
 * @param success
 * @param failure
 * @param exceptionHandler
 */
export const fetchProductDetails = (dispatch, uuid,success, failure, exceptionHandler) => {
    const taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + '/product/'+uuid+'?expand=status,product_parameter';
    axios.get(taskURI).then(v => {
        const data = v.data;
        if (data.error) {
            failure({
                code: 500,
                key: "ERROR",
                msg:data.error
            });
        } else {
            success(data.product);
        }

    }).catch(error => {
        errorMiddleware(error,exceptionHandler);
    });
}



/**
 *
 * @param dispatch
 * @param taskURI
 * @param dataSimulation
 * @param success
 * @param failure
 * @param exceptionHandler
 */
export const fetchToProductSimulation = (dispatch,taskURI, dataSimulation,success, failure, exceptionHandler) => {
     taskURI = ConfigsApi.default[ConfigsEnv.deploy].endpoint + taskURI;
    axios.post(taskURI,dataSimulation).then(v => {
        const data = v.data;
        if (data.error) {
            failure({
                code: 500,
                key: "ERROR",
                message:data.error
            });
        } else {
            success(data.simulation);
        }

    }).catch(error => {
        errorMiddleware(error,exceptionHandler);
    });
}




