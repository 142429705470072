import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {HashRouter} from "react-router-dom";
import * as serviceWorker from "./web_modules/serviceWorker";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <HashRouter forceRefresh={true}>
        <App/>
    </HashRouter>
);


// Si vous souhaitez que votre application fonctionne hors ligne et se charge plus rapidement, vous pouvez modifier
// unregister() pour register() ci-dessous. Notez que cela comporte quelques pièges.
// En savoir plus sur les techniciens de service : https://bit.ly/CRA-PWA
serviceWorker.unregister();
