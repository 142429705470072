import React from "react";
import {Link} from "react-router-dom";

export const Footer = () => {
    return (
        <footer className="bg-white py-2 px-8 font-sans tracking-wide fixed bottom-0 left-0 right-0 border-t-indigo-500 border-top">
            <div className="flex max-lg:flex-col items-center justify-between gap-6">
                <ul className="hidden lg:flex flex-wrap justify-center gap-x-6 gap-4">
                    <li> <Link to="#" className="text-xl hover:text-gray-400">
                        <img src={process.env.PUBLIC_URL +"/assets/images/footer_jars.png"} alt="logo" className='w-5'/>
                    </Link></li>
                </ul>
                <p className='text-base  max-lg:order-1'>Copyright © 2024<Link
                    to='https://jarstechnologies.com' target='_blank'
                    className="hover:underline mx-1 text-blue-700">JARS Technologies International</Link>Tous droits réservés.</p>
                <ul className="hidden lg:flex gap-x-6 gap-y-2 flex-wrap">
                    <li>
                         <Link to="#" className="text-base flex justify-center gap-x-1 text-blue-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[10px] -rotate-90"
                                 viewBox="0 0 24 24">
                                <path fillRule="evenodd"
                                      d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z">
                                </path>
                            </svg>
                            Conditions d'utilisation
                        </Link>
                    </li>
                    <li>
                         <Link to="#" className="text-base flex justify-center gap-x-1 text-blue-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[10px] -rotate-90"
                                 viewBox="0 0 24 24">
                                <path fillRule="evenodd"
                                      d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z">
                                </path>
                            </svg>
                            Politique de confidentialité
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className="text-base flex justify-center gap-x-1 text-blue-700">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-[10px] -rotate-90"
                                 viewBox="0 0 24 24">
                                <path fillRule="evenodd"
                                      d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z">
                                </path>
                            </svg>
                            À propos
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
