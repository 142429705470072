import React from 'react';
import {NumericFormat} from 'react-number-format';

const AmountInput = ({value, onChange, step, ...props}) => {
    const handleChange = (values) => {
        const {value} = values;
        onChange(step, props.name, {
            target: {
                value: value
            }
        });
    };

    return (
        <NumericFormat
            value={value}
            onValueChange={handleChange}
            thousandSeparator=" "
            decimalSeparator="."
            decimalScale={0}
            fixedDecimalScale
            allowNegative={false}
            customInput="input"
            {...props}
        />
    );
};

export default AmountInput;
