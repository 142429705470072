import React, {createContext, useContext, useEffect, useMemo, useState} from 'react';
import io from 'socket.io-client';
import ConfigsSocket from '../configs/Socket';
import ConfigsEnv from "../configs/Env";
import {RootStateContext} from "./RootProvider";
import SOCKET_TYPE_EVENTS_USER from "../actions/constants/sockets/user";
import SOCKET_TYPE_EVENTS_GROUP from "../actions/constants/sockets/group";


const SocketContext = createContext(undefined);

export const SocketProvider = ({ children }) => {
    const rootStateContext = useContext(RootStateContext);
    const {user,auth} = rootStateContext;

    const  infoClient = useMemo(()=>{
        return {
            userId: user.id, // Remplacez par l'ID du client
            groups_in: user.groups_in, // Remplacez par les groupes auxquels le client appartient
            friends: user.friends, // Remplacez par les amis du client
            sockets: [], // Initialement, la liste des sockets est vide
            groups: user.groups, // Remplacez par les groupes du client
            access_token: auth.access_token, // Remplacez par le jeton JWT du client
        };
    },[user,auth]);

    const [socket, setSocket] = useState(null);

    useEffect(() => {
        if (!auth.isLoggedIn){
            return void(0);
        }
        /*const newSocket = io(ConfigsSocket.default[ConfigsEnv.deploy].endpoint);
        setSocket(newSocket);

        newSocket.on(SOCKET_TYPE_EVENTS_USER.NEW_CONNECTION, () => {
            // Capture de l'événement 'newConnection' côté client
            // Émission de l'événement 'loginConnexion' avec les informations du client

            newSocket.emit(SOCKET_TYPE_EVENTS_USER.LOGIN_CONNECTION, infoClient);
        });

        newSocket.on(SOCKET_TYPE_EVENTS_USER.LOGIN_SUCCESS, (data) => {
            // Capture de l'événement 'friendOnline' côté client
            //console.log(`Votre connexion s'est bien passee`);
        });

        newSocket.on(SOCKET_TYPE_EVENTS_USER.ONLINE_FRIENDS_LIST, (friends) => {
            //friends==>[ids des friends deja en ligne]
            // Capture de l'événement 'onlineFriendsList' côté client
            //console.log("apres ta connexion voici la liste des amis qui sont deja en ligne : "+ JSON.stringify(Array.from(friends)));
            Array.from(friends).forEach(({friendId,online})=>{
                if (friendId){
                    document.querySelector(':root').style.setProperty('--default-back-color-'+friendId,online===true ?'#0ac70a':'#c5bfbf');
                }
            })

        });


        newSocket.on(SOCKET_TYPE_EVENTS_USER.FRIEND_ONLINE, (infoClientSocket) => {
            document.querySelector('[data-status-online="'+infoClientSocket.userId+'"]') &&
            document.querySelector('[data-status-online="'+infoClientSocket.userId+'"]').classList.add('online');
            //infoClient => {userId: infoClient.userId}
            // Capture de l'événement 'friendOnline' côté client
            //console.log(`L'ami avec l'ID ${infoClientSocket.userId}  est en ligne.`);
            document.querySelector(':root').style.setProperty('--default-back-color-'+infoClientSocket.userId,'#0ac70a');

        });

        //
        newSocket.on(SOCKET_TYPE_EVENTS_USER.FRIEND_OFFLINE, (infoClientSocket) => {
            document.querySelector('[data-status-online="'+infoClientSocket.userId+'"]') &&
            document.querySelector('[data-status-online="'+infoClientSocket.userId+'"]').classList.remove('online');
            //infoClient {userId: infoClient.userId}
            // Capture de l'événement 'friendOnline' côté client
            //console.log(`L'ami avec l'ID ${infoClientSocket.userId} n'est plus en ligne.`);
            document.querySelector(':root').style.setProperty('--default-back-color-'+infoClientSocket.userId,'#c5bfbf');
        });

      /!*  newSocket.on(SOCKET_TYPE_EVENTS_GROUP.RECEIVED_MESSAGE_IN_GROUP, (data)=>{
            console.log('SOCKET_TYPE_EVENTS_GROUP.RECEIVED_MESSAGE_IN_GROUP '+ data);
        });*!/


        newSocket.on(SOCKET_TYPE_EVENTS_GROUP.USER_DISCONNECTED_IN_GROUP, (data)=>{
            // Capture de l'événement 'userDisconnected' côté client de la deconnexion d'un utilisateur du groupe de discussion
            //console.log(`Le membre avec l'ID ${data.userId} n'est plus en ligne pour le groupe ${data.groupId}.`);
        });

        newSocket.on(SOCKET_TYPE_EVENTS_USER.USER_DISCONNECTED, () => {
            console.log('user disconnected');
        });

        // Déconnectez le socket lorsque le composant est démonté
        return () => {
            newSocket.disconnect();
            newSocket.off(SOCKET_TYPE_EVENTS_USER.NEW_CONNECTION);
            newSocket.off(SOCKET_TYPE_EVENTS_USER.LOGIN_SUCCESS);
            newSocket.off(SOCKET_TYPE_EVENTS_USER.FRIEND_ONLINE);
            newSocket.off(SOCKET_TYPE_EVENTS_USER.FRIEND_OFFLINE);
            newSocket.off(SOCKET_TYPE_EVENTS_USER.USER_DISCONNECTED);
            //newSocket.off(SOCKET_TYPE_EVENTS_GROUP.RECEIVED_MESSAGE_IN_GROUP);

        };*/
    },[auth.isLoggedIn]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => {
    return useContext(SocketContext);
};
