import DefaultRouters from "./Default";
import AuthRouters from "./Auth";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {RootDispatchContext, RootStateContext} from "../providers/RootProvider";
import AppLoading from "../components/layouts/AppLoading";
import {ACCOUNT_ACTIONS, AUTH_ACTIONS, THEME_ACTIONS} from "../actions/constants/Index";
import toast, {Toaster} from "react-hot-toast";
import axios from "axios";
import {useQuery} from "../hooks/useQuery";
import {authTokenLogin} from "../actions/dispatch/auth";


export default () => {
    const query = useQuery();
    const amonRa = query.get('amonRa');
    const rootStateContext = useContext(RootStateContext);
    const rootDispatchContext = useContext(RootDispatchContext);
    const [isLoadingApp, setIsLoadingApp] = useState(true);
    const _loadRetrieveInitialState = useCallback(() => {
        Promise.all([
            localStorage.getItem("pid_user"),
            localStorage.getItem("pid_theme"),
        ]).then((_values) => {
            //Theme
            let pid_theme = _values[1];
            if (pid_theme) {
                pid_theme = JSON.parse(pid_theme);
                if (pid_theme) {
                    rootDispatchContext({
                        type: THEME_ACTIONS.SET_RETRIEVE,
                        payload: pid_theme
                    });
                    document.documentElement.setAttribute("data-theme", pid_theme.mode);
                }
            }

            //User
            let pid_user = _values[0];
            if (pid_user) {
                pid_user = JSON.parse(pid_user);
                if (pid_user) {
                    rootDispatchContext({
                        type: ACCOUNT_ACTIONS.SET_RETRIEVE,
                        payload: pid_user
                    });
                    axios.defaults.headers.common['authorization'] = 'Bearer ' + pid_user.auth.access_token;
                    rootDispatchContext({
                        type: AUTH_ACTIONS.SET_LOGGED_IN,
                        payload: {...pid_user, isLoggedIn: true}
                    });
                    setIsLoadingApp(false);
                } else {
                    setIsLoadingApp(false);
                }
            } else {
                if (amonRa) {
                    authTokenLogin(rootDispatchContext, amonRa,
                        (user) => {
                            axios.defaults.headers.common['authorization'] = 'Bearer ' + user.auth.access_token;
                            rootDispatchContext({
                                type: ACCOUNT_ACTIONS.UPDATE_PROFILE,
                                payload: user
                            });
                            localStorage.setItem("pid_user", JSON.stringify(user));
                            setIsLoadingApp(false);
                        }, (error) => {
                            toast.error(error.message);
                            setIsLoadingApp(false);
                        });
                } else {
                    setIsLoadingApp(false);
                }
            }
        });
    }, [rootDispatchContext, amonRa]);

    useEffect(() => {
        _loadRetrieveInitialState();
    }, [_loadRetrieveInitialState]);


    useEffect(() => {
        //loadJS(`https://meet.yuzspace.com/assets/js/bundle1c4a.js`);
    }, [])


    if (isLoadingApp) {
        return <AppLoading isAbsolute={true}/>;
    }
    return <>
        {rootStateContext?.user?.auth?.isLoggedIn ? (
            <DefaultRouters/>
        ) : (<AuthRouters/>)}
        <Toaster/>
    </>
}
