export const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
};

/**
 * @default Permet d'exclure les cle d'un objet
 * @param data
 * @param keys
 * @returns {T}
 */
export const excludeObjectByKeys = (data, keys) => {
    return Object.keys(data)
        .filter((key) => !keys.includes(key))
        .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
        }, {});
};

export const formatAmount = (amount) => {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'XOF',
    }).format(amount);
}


export const getChartDta = (data) => {
    let accumulated_savings = data.accumulated_savings;
    accumulated_savings = Object.values(accumulated_savings)
    if (accumulated_savings.length > 10) accumulated_savings = accumulated_savings.slice(0, 10);

    let contributions = data.contributions;
    contributions = Object.values(contributions)
    if (contributions.length > 10) contributions = contributions.slice(0, 10);

    let interests = data.interests;
    interests = Object.values(interests);
    if (interests.length > 10) interests = interests.slice(0, 10);
    let categories = ["1ère année", "2ème année", "3ème année", "4ème année", "5ème année", "6ème année", "7ème année", "8ème année", "9ème année", "10ème année"];
    categories = categories.slice(0, accumulated_savings.length);
    return {
        series: [{
            name: "Epargne constituée",
            data: accumulated_savings.slice(0, 10)
        },{
            name: "Cumul Cotisation",
            data: contributions.slice(0, 10)
        },
            {
            name: "Interêts",
            data: interests.slice(0, 10)
        }],
        options: {
            title: {
                text: 'Evolution du Compte financier'
            },
            colors: ['#112957','#aaa', '#ffc107'],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Montant'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " F CFA"
                    }
                }
            }
        }
    }
}

/**
 *
 * @param dateString
 * @param min
 * @param max
 * @returns {boolean}
 */
export const isValidDate = (dateString,min=18,max=60) => {
    function _isValidDate(dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime()); // Vérifie si la date est valide
    }

    function _isAgeValid(dateString) {
        const today = new Date();
        const birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age >= min && age <= max;
    }
    return _isValidDate(dateString) && _isAgeValid(dateString)
}
