import React, {useContext} from "react";
import AppPreventLoading from "../AppPreventLoading";
import {RootStateContext} from "../../../providers/RootProvider";
import {Header} from "./Header";
import {Footer} from "./Footer";

export const MasterLayoutProvider = ({children}) => {
    const rootStateContext = useContext(RootStateContext);

    return (
        <div className="relative">
            <Header/>
            {!rootStateContext.appState.isPreventLoading && (

                <div>
                    <div className="main-container">
                        {children}
                    </div>
                </div>
            )}
            {rootStateContext.appState.isPreventLoading && <AppPreventLoading/>}
            <Footer/>
        </div>
    );
}
